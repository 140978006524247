<template>
  <div
    class="w-full bg-white  overflow-y-auto"
    style="height: calc(100vh - 200px )"
  >
    <ul class="p-0 w-full">
      <li class="h-12 text-gray-800" v-for="(item, i) in items" :key="i">
        <router-link
          :to="item.link"
          class="flex items-center px-4 h-full rounded-lg"
          @click="updateClass(item.link)"
        >
          <div class="mr-2 w-5 h-5 left-img">
            <img :src="icon(item.icon)" />
          </div>
          <div class="right-content">
            <p class="text-sm font-bold truncate">
              {{ $t(`components.settingsManagement.navigation.${item.key}`) }}
            </p>
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['item'],
  data: () => ({
    items: [
      {
        key: 'general',
        icon: 'support',
        link: 'general',
      },
      {
        key: 'security',
        icon: 'operator',
        link: 'security',
      },
      {
        key: 'trip',
        icon: 'vehicle',
        link: 'trip',
      },
      {
        key: 'rent',
        icon: 'vehicle',
        link: 'rent',
      },
      {
        key: 'payments',
        icon: 'payment',
        link: 'payment',
      },
      {
        key: 'iot',
        icon: 'vehicle',
        link: 'iot',
      },
      {
        key: 'vehicle',
        icon: 'vehicle',
        link: 'vehicle',
      },
      {
        key: 'point',
        icon: 'points',
        link: 'point',
      },
      {
        key: 'events',
        icon: 'event',
        link: 'event',
      },
      {
        key: 'operatorApp',
        icon: 'operator',
        link: 'operator',
      },
      {
        key: 'riderApp',
        icon: 'mobile',
        link: 'rider-app',
      },
      {
        key: 'email',
        icon: 'email',
        link: 'email',
      },
      {
        key: 'businessHour',
        icon: 'support',
        link: 'schedule',
      },
      {
        key: 'profile',
        icon: 'operator',
        link: 'profile',
      },
    ],
  }),
  // computed : {
  //   active : {
  //     status : false,
  //     set(){
  //       this.status = !this.status
  //     },
  //     get(){
  //       return this.status
  //     }
  //   }
  // },
  methods: {
    icon(icon) {
      return require(`@/assets/icon/settings/${icon}-black.svg`)
    },
  },
  watch: {
    // $router: () => {
    //   console.log("habib");
    // },
  },
  computed: {
    // activeClass() {
    //   let currentRoute = this.$router.currentRoute.path
    //     .split("/")
    //     .slice(0, -1)
    //     .join();
    //   if ("link" != currentRoute) {
    //     return;
    //   }
    //   return "active";
    // },
  },
  // mounted() {
  //   console.log(
  //     this.$router.currentRoute.path
  //       .split("/")
  //       .slice(0, -1)
  //       .join()
  //   );
  // },
}
</script>

<style lang="scss" scoped>
.router-link-active {
  background-color: #256baa;
  color: #fff;
}
</style>
